import Image from "next/legacy/image";
import Link from "next/link";

type NotFoundErrorProps = Partial<Record<"title" | "description", string>>;

export const NotFoundError = ({
  title = "找不到頁面",
  description = "請檢查地址欄中的 URL，然後重試。",
}: NotFoundErrorProps) => {
  return (
    <div className="flex flex-1 flex-col items-center justify-center space-y-6 text-base font-normal sm:space-y-8">
      <div className="relative h-32 w-40 sm:h-40 sm:w-52">
        <Image alt="404" src="/images/404.png" layout="fill" unoptimized />
      </div>
      <div className="inline-flex flex-col items-center space-y-4 pt-4">
        <div className="text-4xl font-medium text-gray-700 sm:text-5xl">
          {title}
        </div>
        <div className="text-gray-500">{description}</div>
      </div>
      <Link href="/" passHref className="text-primary">
        返回首頁 →
      </Link>
    </div>
  );
};
