import { NotFoundError } from "@/features/common/components/NotFoundError";
import { SiteLayout } from "@/features/layout/SiteLayout";

const Custom404Page = () => {
  return <NotFoundError />;
};

export default Custom404Page;

Custom404Page.getLayout = function getLayout(page) {
  return <SiteLayout>{page}</SiteLayout>;
};
